<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="d-flex">
      <SubMenu :nameModule="nameModule" :subMenu="subMenu" />
  
      <!-- Seccion para cargar el contenido de la navegación del modulo-->
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
    </div>
  </template>
   
   
   <!-- #################################################################################### -->
   <!-- ###### Sección de Scripts                                                     ###### -->
   <!-- #################################################################################### -->
  <script>
  import { mapState } from "vuex";
  import SubMenu from '@/components/SubMenu.vue';
  import { Role } from "@/router/role.js";
  
  export default {
    name: 'ValidatorMeshMain',
    components: { SubMenu },
    data: () => ({
      tab: null,
      nameModule: "Rutas de Gestión Integral", 
      userRoles: {},
      roles: Role,
      subMenu: [
        {
          name: "Malla Validadora",
          icon: "fact_check",
          rol: Role.MallaValidadora_SolicitudServicio,
          link: {name:'modules.validatorMeshMain.autoimmuneMesh'}
        },
        {
          name: "Solicitud Servicios",
          icon: "post_add",
          rol: Role.MallaValidadora_SolicitudServicio,
          link: {name:'modules.validatorMesh.serviceRequest'}
        },
      ],
  
    }),
    computed: {
      ...mapState(["auth"]),
    },
    created() {
      this.userRoles = this.auth.roles;
    },
  }
  </script>
   
   <!-- #################################################################################### -->
   <!-- ###### Sección de Styles                                                      ###### -->
   <!-- #################################################################################### -->
  <style scoped></style>